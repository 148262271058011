<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-center">
          <app-collapse
            accordion
            type="margin"
          />
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              label=""
              label-size="sm"
              class="form-inline"
            >
              <b-form-input
                v-model="trenches.search"
                class="d-inline-block mr-1"
                placeholder="Search transactions"
                type="text"
              />
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay
            :show="$apollo.loading"
            spinner-variant="primary"
          >
            <vue-good-table
              :columns="trenches.columns"
              :pagination-options="{
                enabled: true,
                perPage:trenches.pageLength
              }"
              :rows="trenchesRows"
              :search-options="{
                enabled: true,
                externalQuery: trenches.search }"
            >
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="trenches.pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="trenches.pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
  // BTab,
  // BTabs,
  BOverlay,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { reactive } from '@vue/composition-api'

export default {
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    // BTab,
    // BTabs,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    AppCollapse,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rounds: [
        { title: '' },
      ],
      selectedRound: 0,
      trenchesRows: [],
    }
  },
  setup() {
    const trenches = reactive(
      {
        selected: {
          id: null,
        },
        search: '',
        applicationForm: null,
        pageLength: 5,
        filter: {
          status: 'Current',
        },
        columns: [
          {
            label: 'Datetime',
            field: 'title',
          },
          {
            label: 'Transaction type',
            field: 'trench_amount',
          },
          {
            label: 'Bank Transaction type',
            field: '',
          },
          {
            label: 'Bank Reference id',
            field: '',
          },
          {
            label: 'Total balance',
            field: '',
          },
        ],
      },
    )
    return {
      trenches,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
